import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import '../assets/styles/pages/page-not-found.scss';

function NotFoundPage() {
  const images = useStaticQuery(graphql`
    query {
      batmoe: file(relativePath: {eq: "batmoe.jpg"}) {
        childImageSharp {
          gatsbyImageData
        }
        publicURL
      }
      page: markdownRemark(fields: {slug: {eq: "/404/404/"}}) {
        frontmatter {
          seo_description
          seo_title
          title
          excerpt
        }
      }
    }
  `);
  const batmoe = getImage(images.batmoe);
  const {
    seo_description,
    seo_title,
    title,
    excerpt,
  } = images.page.frontmatter;
  return (
    <Layout classWrapper="header-not-over page-not-found--wrapper" headerDark={false}>
      <SEO
        title={seo_title}
        description={seo_description}
      />
      <GatsbyImage
        image={batmoe}
        alt="404 background"
      />
      <div className="container">
        <div className="page-not-found--text-wrapper">
          <h1>{title}</h1>
          <p>{excerpt}</p>
        </div>
      </div>
    </Layout>
  );
}

export default NotFoundPage;
